import React, { Component } from "react"

/* Widgets */
import { TableCards, Rates, Chips, Preloader } from './widgets'

/* Components */
import { Fold, Bet, Start, Payout, Check } from './components'

/* Socket IO */
import { io } from "socket.io-client"

/* Alicorn ep-ui */
import { Background, Balance, Result, Notification, Internet, InactionTiming, GameInfo, Transactions, Error } from "@alicorn/ep-ui"

/* JWT */
import { decodeToken } from 'react-jwt'

/* REST API */
import { history } from './api/History'

/* Game quit alicorn */
import { setIframeMessageSenderSettings, useSendInitIframeMessage } from '@alicorn/iframe-message-sender'

/* Constants */
import { env, utils, sound } from "./constants"
import { ERROR, CHOICE, DEALING, LOADING, PRE_FLOP, FLOP, TURN_RIVER } from "./constants/status"

/* Rules */
import { rules } from "./constants/rules"

/* Redux */
import { connect } from 'react-redux'

/* Fields */
const BOX = [0, 1]
const pathname = window.location.search
const token = pathname.length > 1 ? pathname.substring(1) : ""

/* FRAME SETTINGS */
setIframeMessageSenderSettings({ parentName: 'makao-front', sourceName: 'poker', isChild: true })


const imgUrl = 'https://repb.rekopgames.com/images/rules/game.png'

/* Entry Point */
class App extends Component {

    constructor() {
        super()

        const data = decodeToken(token)

        this.state = {
            status: LOADING,

            gameBox: "all",
            ante: [0, 0],
            trips: [0, 0],
            selected: 2,
            last: [{ ante: 0, trips: 0 }, { ante: 0, trips: 0 }],
            bet: [0, 0],
            list: [],
            balance: 0,
            freezedSum: 0,
            total: 0,
            lastWin: 0,

            solution: ["", ""],
            open: [false, false],

            playerCards: [[], []],
            playerPreflopCards: [[], []],
            playerGame: [null, null],

            additionalCards: [],

            dealerCards: [],
            dealerPreflopCards: [],
            dealerGame: null,

            tripsResult: [null, null],
            result: [null, null],
            chip: [false, false],

            gameInfo: null,
            transactions: [],
            operations: [],
            histories: [],
            historyLoading: true,
            historyPage: 1,
            historyHasMore: true,

            /* Game Session */
            closed: false,
            newDevice: false,
            isDisconnected: false,

            currency: data.currency,
            chips: utils.getChips(data.chips),
            game: {
                max: data.max,
                maxTrips: data.maxBonus,
                min: data.min,
                maxPay: data.maxPay
            },

            volume: false,
            locale: data.locale ? data.locale : "ru"
        }

        /* Start connection */
        this.socket = io(env.endpoint, { auth: { token: `${token}`, reconnection: false, isPlayer: true } })
    }

    componentDidMount = () => {

        const { locale } = this.state
        const { setLanguage } = this.props
        setLanguage(locale)

        this.game()
        this.loadHistory()
    }

    /* Load History */
    loadHistory = (page = 1) => {

        const { histories } = this.state

        if (page > 1) {
            this.setState({ historyPage: page })
        }

        history(token, page).then(response => {
            if (response.status === 200) {
                let list = response.data
                if (page > 1) {
                    list = [...histories, ...response.data]
                }

                if (response.data.length < 5) {
                    this.setState({ historyHasMore: false })
                }

                this.setState({ histories: list, historyLoading: false })
            }
        }).catch(() => {
            this.setState({ histories: [], historyLoading: false })
        })
    }


    game = () => {
        /* On error */
        this.socket.on("connect_error", () => {

            const timer = setTimeout(() => {
                this.setState({ status: ERROR })
                clearTimeout(timer)
            }, 1000)

        })

        /*  */
        this.socket.on("disconnect", () => {
            this.setState({ isDisconnected: true })
        })

        /* On connect */
        this.socket.on("connect", () => {
            const timer = setTimeout(() => {
                if (this.state.status !== CHOICE) {
                    this.setState({ status: CHOICE })
                    this.clear()
                }
                clearTimeout(timer)
            }, 2000)
        })

        /* On New Device */
        this.socket.on("newDeviceConnection", () => {
            this.setState({ newDevice: true })
            this.socket.disconnect()
        })


        /* Reconnection */
        this.socket.on("reconnection", data => {

            const time = data.transactionError ? 3500 : 2000

            if (data.transactionError) {
                utils.toggleError(utils.translate("Transaction error"))
            }

            let solution = ['', '']
            data.ante.forEach((item, index) => {
                if (item) {
                    if (data.check[index]) {
                        solution[index] = "CHECK"
                    }
                    if (data.pass[index]) {
                        solution[index] = "PASS"
                    }
                    if (data.bet[index]) {
                        solution[index] = "BET"
                    }
                }
            })

            setTimeout(() => {
                this.setState({
                    status: data.status,
                    gameInfo: data.gameInfo,
                    gameBox: data.gameBox,
                    solution,
                    ante: data.ante,
                    trips: data.trips,
                    total: data.total,
                    bet: data.bet,
                    pass: data.pass,
                    playerCards: data.playerCards,
                    playerPreflopCards: data.playerPreflopCards,
                    dealerCards: data.dealerCards,
                    dealerPreflopCards: data.dealerPreflopCards,
                    additionalCards: data.additionalCards,
                    playerGame: data.playerGame,
                    dealerGame: data.dealerGame,
                    tripsResult: data.tripsResult,
                    result: data.result,
                    transactions: data.transactions
                })
            }, time)
        })

        /* On start */
        this.socket.on("status", data => {
            const { status } = this.state
            if (data !== status) {
                this.setState({ status: data })
            }

            if (data === CHOICE) {
                this.clear()
                this.loadHistory()
            }
        })


        /* Balance */
        this.socket.on("balance", data => {
            this.setState({ balance: data })
        })

        /* Remove freezed sum */
        this.socket.on("removeFreezedSum", () => {
            this.setState({ freezedSum: 0 })
        })

        /* Get a dealer card */
        this.socket.on("dealer", data => {

            const { card } = data

            if (card) {

                const { dealerPreflopCards, volume } = this.state
                const i = dealerPreflopCards.findIndex(e => e.uuid === card.uuid)
                let cards = dealerPreflopCards

                if (i === -1) {
                    cards.push(card)
                    this.setState({ dealerPreflopCards: cards })

                    if (volume) {
                        sound.play('card', 0.2)
                    }
                }
            }

        })

        this.socket.on("resultData", data => {

            const { volume } = this.state
            const { dealerGame, result, cards, dealerPreflopCards } = data

            let win = 0
            result.forEach(item => {
                if (item) {
                    win += item.sum
                }
            })

            this.setState({ dealerCards: cards, dealerPreflopCards, dealerGame, result, lastWin: win })

            if (result && ((result[0] && result.result === "win") || (result[1] && result[1].result === "draw"))) {
                if (volume) {
                    sound.play('win', 0.2)
                }
            }

        })

        this.socket.on("tripsResult", tripsResult => {
            this.setState({ tripsResult })
        })

        /* Get a Player card */
        this.socket.on("player", data => {

            const { card, box } = data
            const { playerPreflopCards, volume } = this.state

            if (card && playerPreflopCards[box]) {

                const i = playerPreflopCards[box].findIndex(e => e.id === card.id)

                if (i === -1) {

                    let cards = playerPreflopCards
                    cards[box].push(card)
                    this.setState({ playerPreflopCards: cards, playerCards: cards })

                    if (volume) {
                        sound.play('card', 0.2)
                    }
                }

            }
        })

        /* Get a Additional card */
        this.socket.on("additional", data => {

            const { card } = data

            const { additionalCards, volume } = this.state

            if (card) {

                const i = additionalCards.findIndex(e => e.id === card.id)

                if (i === -1) {

                    let cards = additionalCards
                    cards.push(card)

                    this.setState({ additionalCards: cards })

                    if (volume) {
                        sound.play('card', 0.2)
                    }

                }

            }
        })

        /* Player cards */
        this.socket.on("playerCards", cards => {

            if (cards) {
                this.setState({ playerCards: cards })
            }

        })

        this.socket.on("playerGame", data => {
            const { playerGame, playerCards } = this.state
            const { hand, box, cards } = data

            playerGame[box] = hand

            if (cards) {
                playerCards[box] = cards
            }

            this.setState({ playerGame, playerCards })
        })

        /* Player action box */
        this.socket.on("actionCheck", box => {
            const { solution } = this.state
            if (solution[box] === "CHECK") {
                solution[box] = ""
            }
            this.setState({ solution })
        })

        /* Result */
        this.socket.on("result", result => {

            const { volume } = this.state

            this.setState({ result })

            if (result && result.result === "win") {
                this.setState({ lastWin: result.sum })
            }

            if (result && (result.result === "win" || result.result === "draw")) {
                if (volume) {
                    sound.play('win', 0.2)
                }
            }
        })

        /* Get Game Info */
        this.socket.on("gameInfo", data => {
            this.setState({ gameInfo: data })
        })

        /* Get transactions list */
        this.socket.on("transaction", data => {
            if (data.id) {
                const { operations } = this.state
                data.reason = utils.translate(data.reason)
                operations.push(data)
                this.setState({ operations })
            }
        })

        this.socket.on("editTransaction", data => {
            const { operations } = this.state
            let list = operations
            const index = list.findIndex(e => e.number === data.number)
            if (index > -1) {
                list[index].status = data.status
                this.setState({ operations: list })
            }
        })


        /* Force End Game */
        this.socket.on("forceEnd", () => {
            this.setState({ status: CHOICE })
            this.loadHistory()
            this.clear()
            utils.toggleError(utils.translate("The game ended due to the administrator's initiative"), "warning", "#222", "#fff", 500, 5000)
        })

        /* Notification From Admin */
        this.socket.on("adminNotification", data => {
            if (data && data.title && data.total && data.currency) {
                utils.toggleError(`${utils.translate(data.title)} ${utils.getWithCurrency(data.total, data.currency)}`, "success", "#222", "#fff", 500, 5000)
            }
        })

    }


    handleClose = (id) => {

        const { showInternetConnectionError } = this.state

        if (id === "internet" && showInternetConnectionError <= 2) {
            this.setState({ showInternetConnectionError: showInternetConnectionError + 1 })
            if (showInternetConnectionError + 1 === 2) {
                setTimeout(() => {
                    this.setState({ showInternetConnectionError: 1 })
                }, 300000)
            }
        }

    }

    /* Check internet speed */
    checkInternetSpeed = (speed) => {

        const { showInternetConnectionError } = this.state

        if (showInternetConnectionError < 2) {
            if (parseInt(speed) < 3) {
                utils.toggleError(utils.translate("Poor internet connection"), "warning", "internet", this.handleClose)
            }
            else {
                utils.toastDismiss('internet')
            }
        }

    }

    /* Disconnect user when inaction timer equal to 0 */
    setInactionState = () => {
        this.setState({ closed: true })
        this.socket.disconnect()
        this.clear()
    }

    /* Reser top timing */
    resetTimer = () => {
        if (this._inaction) {
            this._inaction.reset()
        }
    }

    clear = () => {
        this.setState({
            gameBox: "all",
            ante: [0, 0],
            trips: [0, 0],
            bet: [0, 0],
            total: 0,
            list: [],
            gameInfo: null,
            solution: ["", ""],
            check: [false, false],
            playerCards: [[], []],
            playerPreflopCards: [[], []],
            playerGame: [],
            additionalCards: [],
            dealerCards: [],
            dealerPreflopCards: [],
            dealerGame: null,
            tripsResult: [],
            result: [],
            operations: [],
            freezedSum: 0,
        })
    }

    /* STAKE RATE */
    setStake = (data, box) => {

        const { ante, trips } = this.state
        const { ante: stakeAnte, trips: stakeTrips } = data

        ante[box] = stakeAnte
        trips[box] = stakeTrips

        const otherBox = box === 0 ? 1 : 0
        const otherTotal = ante[otherBox] * 2 + trips[otherBox]

        this.setState({ ante, trips, total: stakeAnte * 2 + stakeTrips + otherTotal, lastWin: 0 })
    }

    isActionAvailable = () => {
        const { status } = this.state
        if (status === PRE_FLOP || status === FLOP || status === TURN_RIVER) {
            return true
        }
        return false
    }


    /* START ACTION */
    start = () => {

        const { ante, trips } = this.state

        let stake = []
        ante.forEach((item, index) => {
            if (item) {
                stake.push({ ante: item, trips: utils.check(trips[index], 0) })
            }
            else {
                stake.push({ ante: 0, trips: 0 })
            }
        })

        if (utils.valueLength(ante) > 0) {
            this.setState({ status: DEALING, last: stake })
            this.socket.emit('start', { ante, trips })

            if (!stake[0].ante || !stake[1].ante) {
                if (stake[0].ante) {
                    this.setState({ gameBox: 0 })
                } else {
                    this.setState({ gameBox: 1 })
                }
            }
        }

        this.resetTimer()
    }


    /* FOLD ACTION */
    fold = box => {

        const { solution } = this.state

        if (this.isActionAvailable() && solution[box] !== "BET" && solution[box] !== "FOLD") {
            solution[box] = "FOLD"
            this.setState({ solution }, () => this.setAction())
        }

        this.resetTimer()
    }

    /* BET ACTION */
    bet = (box, betX) => {

        const { ante, bet, total, solution, volume, freezedSum } = this.state

        if (this.isActionAvailable() && solution[box] !== "BET" && solution[box] !== "FOLD") {


            solution[box] = "BET"
            const value = ante[box] * betX
            bet[box] = betX

            this.setState({ solution, bet, total: total + value, freezedSum: freezedSum + value }, () => this.setAction())

            if (volume) {
                sound.play('sound', 0.2)
            }

        }

        this.resetTimer()
    }


    /* CHECK ACTION */
    check = box => {

        const { solution } = this.state

        if (this.isActionAvailable() && solution[box] !== "BET" && solution[box] !== "FOLD") {
            solution[box] = "CHECK"
            this.setState({ solution }, () => this.setAction())
        }

        this.resetTimer()
    }


    /* Set action */
    setAction = () => {

        const { ante, solution, bet } = this.state

        if (utils.valueLength(ante) === utils.valueLength(solution)) {
            this.socket.emit("action", { solution, bet })
            this.setState({ status: DEALING })
        }
    }

    /* Draw Button */
    _buttons = box => {

        const { status, solution, gameBox } = this.state

        if ((status === PRE_FLOP || status === FLOP || status === TURN_RIVER) && solution[box] === "") {

            let betArray = []
            if (status === PRE_FLOP) {
                betArray = [4, 3]
            }
            if (status === FLOP) {
                betArray = [2, 1]
            }
            if (status === TURN_RIVER) {
                betArray = [1]
            }

            const checkVisible = status === PRE_FLOP || status === FLOP
            const passVisible = status === TURN_RIVER

            const style = gameBox !== "all" ? "" : box === 0 ? "right" : "left"

            return (
                <div className={`buttons-box ${style}`}>
                    {betArray.map((item, index) => {
                        return <Bet value={item} index={index} onClick={(value) => this.bet(box, value)} key={`bet-${item}`} />
                    })}

                    {checkVisible ? <Check onClick={() => this.check(box)} /> : null}
                    {passVisible ? <Fold onClick={() => this.fold(box)} /> : null}
                </div>
            )
        } else {
            <div className="buttons-box" />
        }
    }

    /* Draw Start Button */
    _start = () => {

        const { ante, status } = this.state

        if (status === "CHOICE") {
            return (
                <div className="start-button-box">
                    <Start onClick={() => this.start()} ante={ante} />
                </div>
            )
        }

    }

    /* Box render */
    boxRender = (box) => {

        const { gameBox, status, chips, ante, trips, bet, result, tripsResult, selected, list, solution, balance, game, currency, volume, locale } = this.state

        const choice = status === CHOICE
        const boxStyle = gameBox !== "all" ? "single" : ""
        const params = { mediapoint: `${env.mediapoint}`, type: "holdem", lang: locale }

        return (
            <div className={`box ${boxStyle}`} key={`box-${box}`}>
                {!choice ? <div className="box-middle-space" /> : null}
                <div className={`box-middleware ${choice ? "choice-middle" : ""}`}>
                    {this._buttons(box)}
                    <Result key={`${box}`} gameBox={gameBox} box={box} result={result[box]} currency={currency} params={params} />
                </div>
                {!choice ? <div className="box-middle-space" /> : null}
                <Rates
                    key={`rate-${box}`}
                    gameBox={gameBox}
                    box={box}
                    all={{ anteList: ante, tripsList: trips }}
                    chips={chips}
                    status={status}
                    ante={ante[box]}
                    trips={trips[box]}
                    result={result[box]}
                    tripsResult={tripsResult[box]}
                    bet={bet[box]}
                    setStake={(data, key) => this.setStake(data, key)}
                    selected={selected}
                    list={list}
                    setList={list => this.setState({ list })}
                    solution={solution[box]}
                    balance={balance}
                    bottomBet={value => this.bet(box, value)}
                    game={game}
                    currency={currency}
                    volume={volume}
                />

            </div>
        )
    }

    /* Draw Content */
    _content = () => {

        /* Fields */
        const { status, gameBox, ante, trips, selected, last, list, playerPreflopCards, playerGame, additionalCards, dealerPreflopCards, dealerGame, balance, game, chips, currency, volume } = this.state

        const choice = status === CHOICE

        const tableCards = { dealerPreflopCards, additionalCards, playerPreflopCards, gameBox, playerGame, dealerGame }

        return (
            <div className="content">

                <TableCards data={tableCards} choice={choice} boxRender={box => this.boxRender(box)} />

                {/* <div className={`game ${!choice ? "visible" : "hidden"}`}>

                    <div />


                </div> */}

                {this._start()}


                <div className={`choice ${choice ? "visible" : "hidden"}`}>
                    <Chips
                        box={0}
                        chips={chips}
                        ante={ante}
                        trips={trips}
                        setStake={(data, key) => this.setStake(data, key)}
                        last={last}
                        selected={selected}
                        setSelected={selected => this.setState({ selected })}
                        list={list}
                        setList={list => this.setState({ list })}
                        balance={balance}
                        game={game}
                        currency={currency}
                        volume={volume}
                    />
                </div>

            </div>
        )
    }

    /* Get Rules */
    getRules = () => {

        const { language } = this.props

        if (rules[language]) {
            return rules[language]
        }

        return rules["ru"]
    }

    render = () => {

        const { status, balance, freezedSum, total, lastWin, currency, game, histories, historyHasMore, historyPage, operations, closed, newDevice, isDisconnected, gameInfo, volume, locale } = this.state
        const { language, setLanguage } = this.props

        const payout = <Payout game={game} currency={currency} />
        const historyData = { histories, historyHasMore }
        const params = { mediapoint: `${env.mediapoint}`, type: "holdem", lang: locale }

        if (status === LOADING) {
            return <Preloader />
        }

        if (status === ERROR || newDevice || closed || isDisconnected) {
            return <Error newDevice={newDevice} closed={closed} isDisconnected={isDisconnected} params={params} />
        }

        return (
            <Background params={params} language={language} setLanguage={language => setLanguage(language)} historyData={historyData} payout={payout} gameInfo={gameInfo} rules={this.getRules()} loadMore={() => this.loadHistory(historyPage + 1)} volume={volume} setVolume={volume => this.setState({ volume })}>
                <div className="app">

                    <InactionTiming time={240} ref={ref => this._inaction = ref} setInactionState={() => this.setInactionState()} />

                    <GameInfo title={`Ultimate Texas Hold'em`} game={game} currency={currency} payout={payout} params={params} />
                    <Balance balance={balance - freezedSum} total={total} lastWin={lastWin} currency={currency} params={params} />
                    <Transactions info={gameInfo} operations={operations} currency={currency} params={params} />

                    {this._content()}

                    <div className="back-outer">
                        <div className="background" />
                        <div className="glass" />
                        <div className="shadow" />
                    </div>

                    {/* <div className={`makao ${(status === GAME || status === DEALING) ? 'visible' : 'hidden'}`}>
                        <Picture src={`${env.mediapoint}/images/makao.png`} alt="Makao" />
                    </div> */}

                    <Notification />

                    <Internet url={imgUrl} setInternetSpeed={data => this.checkInternetSpeed(data)} />

                    <HookWrapper />

                </div>
            </Background>
        )
    }

}
const mapStateToProps = state => {
    return {
        language: state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLanguage: data => dispatch({ type: 'SET_LANGUAGE', payload: data })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

function HookWrapper() {
    useSendInitIframeMessage()
    return null
}